import { Anchor, Box, Button, Flex, Text } from 'components/primitives'
import {
  DropdownMenuItem,
  DropdownMenuContent,
} from 'components/primitives/Dropdown'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { Avatar } from 'components/primitives/Avatar'
import * as RadixDialog from '@radix-ui/react-dialog'
import {
  faBars,
  faXmark,
  faRightFromBracket,
  faCopy,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import Link from 'next/link'
import Image from 'next/image'
import { useAccount, useDisconnect } from 'wagmi'
import { useRouter } from 'next/router'
import { ConnectWalletButton } from 'components/ConnectWalletButton'
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'
import { FullscreenModal } from 'components/common/FullscreenModal'
import { useENSResolver, useMarketplaceChain } from 'hooks'
import ThemeSwitcher from 'components/navbar/ThemeSwitcher'
import Wallet from 'components/navbar/Wallet'
import SignIn from 'components/SignIn'
import { useApp } from 'context/NeynarContextProvider'
import CopyText from 'components/common/CopyText'
import useLocalStorage from 'hooks/useLocalStorageState'
import { UserInfo } from 'types/UserInfo'
import { useState } from 'react'

const HamburgerMenu = () => {
  const { address, isConnected } = useAccount()
  const router = useRouter()
  const {
    avatar: ensAvatar,
    shortAddress,
    shortName: shortEnsName,
  } = useENSResolver(address)
  const { disconnect } = useDisconnect()
  const [open, setOpen] = useState(false)
  const [user, _1, removeItem] = useLocalStorage<UserInfo>('user')
  const { routePrefix } = useMarketplaceChain()
  const { userData } = useApp()

  const handleSignout = () => {
    removeItem()
    window.location.reload()
  }

  const genres = ['edm', 'hip-hop', 'latin-house', 'pop', 'techno', 'trap']

  const trigger = (
    <Button
      css={{ justifyContent: 'center', width: '44px', height: '44px' }}
      type="button"
      size="small"
      color="gray3"
    >
      <FontAwesomeIcon icon={faBars} width={16} height={16} />
    </Button>
  )

  return (
    <FullscreenModal trigger={trigger}>
      {' '}
      <Flex
        css={{
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Flex
          css={{
            py: '$4',
            px: '$4',
            width: '100%',
            borderBottom: '1px solid $gray4',
          }}
          align="center"
          justify="between"
        >
          <Link href="/">
            <Box css={{ width: 46, cursor: 'pointer' }}>
              <Image src="/logo.svg" width={36} height={36} alt="Reservoir" />
            </Box>
          </Link>
          <RadixDialog.Close>
            <Flex
              css={{
                justifyContent: 'center',
                width: '44px',
                height: '44px',
                alignItems: 'center',
                borderRadius: 8,
                backgroundColor: '$gray3',
                color: '$gray12',
                '&:hover': {
                  backgroundColor: '$gray4',
                },
              }}
            >
              <FontAwesomeIcon icon={faXmark} width={16} height={16} />
            </Flex>
          </RadixDialog.Close>
        </Flex>
        {isConnected ? (
          <Flex
            css={{
              flexDirection: 'column',
              justifyContent: 'flex-start',
              height: '100%',
              py: '$5',
              px: '$4',
            }}
          >
            <Link href={`/portfolio/${address || ''}`} legacyBehavior>
              <Flex
                css={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  cursor: 'pointer',
                  pb: '$4',
                }}
              >
                <Flex css={{ alignItems: 'center' }}>
                  {ensAvatar ? (
                    <Avatar
                      size="medium"
                      src={ensAvatar}
                      fallback={
                        <Jazzicon
                          diameter={36}
                          seed={jsNumberForAddress(address as string)}
                        />
                      }
                    />
                  ) : (
                    <Jazzicon
                      diameter={36}
                      seed={jsNumberForAddress(address as string)}
                    />
                  )}
                  <Text style="subtitle1" css={{ ml: '$2' }}>
                    {shortEnsName ? shortEnsName : shortAddress}
                  </Text>
                </Flex>
              </Flex>
            </Link>
            <Link href="/" legacyBehavior>
              <Text
                style="subtitle1"
                css={{
                  borderBottom: '1px solid $gray4',
                  cursor: 'pointer',
                  pb: '$4',
                  pt: '24px',
                }}
              >
                Explore
              </Text>
            </Link>

            <Text
              style="subtitle1"
              css={{
                borderBottom: '1px solid $gray4',
                cursor: 'pointer',
                pb: '$4',
                pt: '24px',
                width: '100%',
              }}
              onClick={() => setOpen(!open)}
            >
              Channels
            </Text>
            {open && (
              <Flex direction="column">
                {genres.map((genre) => (
                  <Link
                    href={{
                      pathname: `/[chain]/genres/${genre}`,
                      query: { chain: routePrefix },
                    }}
                    legacyBehavior
                    key={genre}
                  >
                    <Text
                      style="subtitle1"
                      css={{
                        borderBottom: '1px solid $gray4',
                        cursor: 'pointer',
                        pb: '$4',
                        pt: '24px',
                        pl: '34px',
                        width: '100%',
                      }}
                    >
                      {genre
                        .split('-')
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(' ')}
                    </Text>
                  </Link>
                ))}
              </Flex>
            )}

            <Link href="/base/collections/trending" legacyBehavior>
              <Text
                style="subtitle1"
                css={{
                  borderBottom: '1px solid $gray4',
                  cursor: 'pointer',
                  pb: '$4',
                  pt: '24px',
                }}
              >
                Trending Collections
              </Text>
            </Link>

            <Link href="/base/mints/trending" legacyBehavior>
              <Text
                style="subtitle1"
                css={{
                  borderBottom: '1px solid $gray4',
                  cursor: 'pointer',
                  pb: '$4',
                  pt: '24px',
                }}
              >
                Trending Mints
              </Text>
            </Link>

            {/* <Anchor
                href="https://docs.reservoir.tools/docs"
                target="_blank"
                css={{
                  borderBottom: '1px solid $gray4',
                  cursor: 'pointer',
                  pb: '$4',
                  pt: '24px',
                  width: '100%',
                }}
              >
                <Text style="subtitle1">Developers</Text>
              </Anchor> */}
            <Link href="/portfolio" legacyBehavior>
              <Flex
                direction="column"
                css={{
                  borderBottom: '1px solid $gray4',
                  cursor: 'pointer',
                  pb: '$4',
                  pt: '24px',
                  gap: '$1',
                }}
              >
                <Text style="subtitle1">Portfolio</Text>
                <Text style="body3" color="subtle">
                  Manage your items, collections, listings and offers
                </Text>
              </Flex>
            </Link>
            <Wallet />
            <Flex
              css={{
                justifyContent: 'space-between',
                cursor: 'pointer',
                alignItems: 'center',
                borderBottom: '1px solid $gray4',
              }}
              onClick={() => disconnect()}
            >
              <Text
                style="subtitle1"
                css={{
                  pb: '$4',
                  pt: '24px',
                }}
              >
                Logout
              </Text>
              <Box css={{ color: '$gray10' }}>
                <FontAwesomeIcon
                  icon={faRightFromBracket}
                  width={16}
                  height={16}
                />
              </Box>
            </Flex>
          </Flex>
        ) : (
          <Flex
            direction="column"
            justify="between"
            css={{
              height: '100%',
              pb: '$5',
              px: '$4',
            }}
          >
            <Flex direction="column">
              <Link href="/" legacyBehavior>
                <Text
                  style="subtitle1"
                  css={{
                    borderBottom: '1px solid $gray4',
                    cursor: 'pointer',
                    pb: '$4',
                    pt: '24px',
                    width: '100%',
                  }}
                >
                  Explore
                </Text>
              </Link>
              <Link href={`/portfolio/${address || ''}`} legacyBehavior>
                <Text
                  style="subtitle1"
                  css={{
                    borderBottom: '1px solid $gray4',
                    cursor: 'pointer',
                    pb: '$4',
                    pt: '24px',
                    width: '100%',
                  }}
                >
                  Portfolio
                </Text>
              </Link>

              <Text
                style="subtitle1"
                css={{
                  borderBottom: '1px solid $gray4',
                  cursor: 'pointer',
                  pb: '$4',
                  pt: '24px',
                  width: '100%',
                }}
                onClick={() => setOpen(!open)}
              >
                Channels
              </Text>
              {open && (
                <Flex direction="column">
                  {genres.map((genre) => (
                    <Link
                      href={{
                        pathname: `/[chain]/genres/${genre}`,
                        query: { chain: routePrefix },
                      }}
                      legacyBehavior
                      key={genre}
                    >
                      <Text
                        style="subtitle1"
                        css={{
                          borderBottom: '1px solid $gray4',
                          cursor: 'pointer',
                          pb: '$4',
                          pt: '24px',
                          pl: '34px',
                          width: '100%',
                        }}
                      >
                        {genre
                          .split('-')
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(' ')}
                      </Text>
                    </Link>
                  ))}
                </Flex>
              )}

              {/* <Anchor
                href="https://docs.reservoir.tools/docs"
                target="_blank"
                css={{
                  borderBottom: '1px solid $gray4',
                  cursor: 'pointer',
                  pb: '$4',
                  pt: '24px',
                  width: '100%',
                }}
              >
                <Text style="subtitle1">Docs</Text>
              </Anchor> */}
            </Flex>
            <Box>
              <ConnectWalletButton />
            </Box>
          </Flex>
        )}
        <Flex
          css={{
            pt: '24px',
            pb: '$5',
            px: '$4',
            gap: '$4',
            width: '100%',
            borderTop: '1px solid $gray4',
          }}
        >
          <Box
            css={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: 10,
            }}
          >
            {userData?.displayName ? (
              <Flex direction="column" css={{ py: '$4', px: '$4' }}>
                <Flex align="center" css={{ flexWrap: 'wrap', width: '100%' }}>
                  {userData?.pfp.url ? (
                    <Avatar size="large" src={userData?.pfp.url} />
                  ) : (
                    <Jazzicon
                      diameter={52}
                      seed={jsNumberForAddress(
                        userData?.custodyAddress as string
                      )}
                    />
                  )}
                  <CopyText
                    text={userData?.custodyAddress || ''}
                    css={{ width: '100%' }}
                  >
                    <Flex direction="column" css={{ width: '100%' }}>
                      <Flex
                        align="center"
                        css={{
                          gap: 8,
                          color: '$gray11',
                          cursor: 'pointer',
                          marginLeft: 10,
                          flexWrap: 'wrap',
                          width: '100%',
                          marginTop: 10,
                        }}
                      >
                        <Text style="body1" css={{ flex: 1 }}>
                          {userData?.displayName}
                        </Text>
                        <FontAwesomeIcon icon={faCopy} width={12} height={14} />
                      </Flex>

                      <Flex
                        align="center"
                        css={{
                          gap: 8,
                          color: '$gray11',
                          cursor: 'pointer',
                          marginLeft: 10,
                          flexWrap: 'wrap',
                          width: '100%',
                        }}
                      >
                        <Text
                          style="body2"
                          color="subtle"
                          css={{ wordWrap: 'break-word', flex: 1 }}
                        >
                          {userData?.profile.bio.text}
                        </Text>
                      </Flex>
                    </Flex>
                  </CopyText>
                </Flex>
                <Button
                  size="large"
                  css={{ my: '$4', justifyContent: 'center' }}
                  color="gray3"
                  onClick={handleSignout}
                >
                  Disconnect
                </Button>
              </Flex>
            ) : (
              <SignIn />
            )}
          </Box>
        </Flex>
        <Flex
          css={{
            pt: '24px',
            pb: '$5',
            px: '$4',
            gap: '$4',
            width: '100%',
            borderTop: '1px solid $gray4',
          }}
        >
          <a
            href="https://x.com/goplatinumxyz?s=21&t=j7p8zCXbul2iu9vUk1UU-g"
            target="_blank"
          >
            <Button
              css={{ justifyContent: 'center', width: '44px', height: '44px' }}
              type="button"
              size="small"
              color="gray3"
            >
              <FontAwesomeIcon icon={faTwitter} width={20} height={20} />
            </Button>
          </a>
          <ThemeSwitcher />
        </Flex>
      </Flex>
    </FullscreenModal>
  )
}

export default HamburgerMenu
