import { useContext, useRef } from 'react'
import { Box, Flex, Card } from '../primitives'
import GlobalSearch from './GlobalSearch'
import { useRouter } from 'next/router'
import { useHotkeys } from 'react-hotkeys-hook'
import Link from 'next/link'
import Image from 'next/image'
import { ConnectWalletButton } from 'components/ConnectWalletButton'
import NavItem from './NavItem'
import HamburgerMenu from './HamburgerMenu'
import MobileSearch from './MobileSearch'
import { useMediaQuery } from 'react-responsive'
import { useMarketplaceChain, useMounted } from '../../hooks'
import { useAccount } from 'wagmi'
import CartButton from './CartButton'
import { AccountSidebar } from 'components/navbar/AccountSidebar'

import * as HoverCard from '@radix-ui/react-hover-card'
import SignIn from 'components/SignIn'
import { useApp } from 'context/NeynarContextProvider'
import { FarcasterSidebar } from './FarcasterSidebar'
import { AudioContext } from 'context/AudioContextProvider'
import MobilePlayerButton from './MobilePlayerButton'
// import { AudioContext } from 'context/AudioContextProvider'
// import FarcasterChatSidebar  from './FarcasterChatSidebar'

export const NAVBAR_HEIGHT = 81
export const NAVBAR_HEIGHT_MOBILE = 77

const Navbar = () => {
  const { userData } = useApp()
  const { isConnected } = useAccount()
  const isMobile = useMediaQuery({ query: '(max-width: 960px' })
  const isMounted = useMounted()
  const { routePrefix } = useMarketplaceChain()
  const { address } = useAccount()
  const { isPlayerMobileOpen, currentListen } = useContext(AudioContext)

  // const { isPlayerChatOpen } = useContext(AudioContext)
  // {isPlayerChatOpen && <FarcasterChatSidebar /> }
  let searchRef = useRef<HTMLInputElement>(null)

  const router = useRouter()
  useHotkeys('meta+k', (e) => {
    e.preventDefault()
    if (searchRef?.current) {
      searchRef?.current?.focus()
    }
  })

  if (!isMounted) {
    return null
  }

  return isMobile ? (
    <Flex
      css={{
        height: NAVBAR_HEIGHT_MOBILE,
        px: '$4',
        width: '100%',
        borderBottom: '1px solid $gray4',
        zIndex: 999,
        background: '$slate1',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
      }}
      align="center"
      justify="between"
    >
      <Box css={{ flex: 1 }}>
        <Flex align="center">
          <Link href={`/${routePrefix}`}>
            <Box css={{ width: 46, cursor: 'pointer' }}>
              <Image src="/logo.svg" width={36} height={36} alt="Reservoir" />
            </Box>
          </Link>
        </Flex>
      </Box>
      <Flex align="center" css={{ gap: '$3' }}>
        {Object.keys(currentListen).length > 0 && <MobilePlayerButton />}
        <MobileSearch key={`${router.asPath}-search`} />
        <CartButton />
        <HamburgerMenu key={`${router.asPath}-hamburger`} />
      </Flex>
    </Flex>
  ) : (
    <Flex
      css={{
        height: NAVBAR_HEIGHT,
        px: '$5',
        '@xl': {
          px: '$6',
        },
        width: '100%',
        // maxWidth: 1920,
        mx: 'auto',
        borderBottom: '1px solid $gray4',
        zIndex: 999,
        background: '$neutralBg',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
      }}
      align="center"
      justify="between"
    >
      <Box
        css={{
          flex: 'unset',
          '@bp1300': {
            flex: 1,
          },
        }}
      >
        <Flex align="center">
          <Link href={`/${routePrefix}`}>
            <Box css={{ cursor: 'pointer' }}>
              <Image
                src="/logo.svg"
                width={50}
                height={50}
                alt="Platinum Protocol"
              />
            </Box>
          </Link>
          <Flex
            align="center"
            css={{
              gap: '$5',
              ml: '$5',
            }}
          >
            <Link href={`/${routePrefix}`}>
              <NavItem>Explore</NavItem>
            </Link>

            <HoverCard.Root openDelay={200}>
              <HoverCard.Trigger>
                <NavItem>Trending</NavItem>
              </HoverCard.Trigger>
              <HoverCard.Content sideOffset={24} align="start">
                <Card css={{ p: 24, width: 240, border: '1px solid $gray4' }}>
                  <Flex css={{ gap: '$4' }} direction="column">
                    <Link href={`/${routePrefix}/collections/trending`}>
                      <NavItem>Collections</NavItem>
                    </Link>
                    <Link href={`/${routePrefix}/mints/trending`}>
                      <NavItem>Mints</NavItem>
                    </Link>
                  </Flex>
                </Card>
              </HoverCard.Content>
            </HoverCard.Root>

            <HoverCard.Root openDelay={200}>
              <HoverCard.Trigger>
                <NavItem>Channels</NavItem>
              </HoverCard.Trigger>
              <HoverCard.Content sideOffset={24} align="start">
                <Card css={{ p: 24, width: 240, border: '1px solid $gray4' }}>
                  <Flex css={{ gap: '$4' }} direction="column">
                    <Link href={`/${routePrefix}/genres/pop`}>
                      <NavItem>Pop</NavItem>
                    </Link>
                    <Link href={`/${routePrefix}/genres/edm`}>
                      <NavItem>EDM</NavItem>
                    </Link>
                    <Link href={`/${routePrefix}/genres/hip-hop`}>
                      <NavItem>Hip Hop</NavItem>
                    </Link>
                    {/* <Link href={`/${routePrefix}/genres/hosue`}>
                      <NavItem>House</NavItem>
                    </Link> */}
                    <Link href={`/${routePrefix}/genres/techno`}>
                      <NavItem>Techno</NavItem>
                    </Link>
                    {/* <Link href={`/${routePrefix}/genres/deep-house`}>
                      <NavItem>Deep House</NavItem>
                    </Link> */}
                    <Link href={`/${routePrefix}/genres/latin-house`}>
                      <NavItem>Latin House</NavItem>
                    </Link>
                    <Link href={`/${routePrefix}/genres/trap`}>
                      <NavItem>Trap</NavItem>
                    </Link>
                    {/* <Link href={`/${routePrefix}/genres/rap`}>
                      <NavItem>Rap</NavItem>
                    </Link>  */}
                  </Flex>
                </Card>
              </HoverCard.Content>
            </HoverCard.Root>

            {false && (
              <Link href={`/${routePrefix}/collections/minting`}>
                <NavItem>Mints</NavItem>
              </Link>
            )}
            {false && (
              <Link href="/swap">
                <NavItem>Tokens</NavItem>
              </Link>
            )}
          </Flex>
        </Flex>
      </Box>
      <Box css={{ flex: 1, px: '$5' }}>
        <GlobalSearch
          ref={searchRef}
          placeholder="Search collections and addresses"
          containerCss={{ width: '100%' }}
          key={router.asPath}
        />
      </Box>

      <Flex
        css={{
          gap: '$3',
          flex: 'unset',
          '@bp1300': {
            flex: 1,
          },
        }}
        justify="end"
        align="center"
      >
        <Flex css={{ gap: '$5', mr: 12 }}>
          <Box>
            <HoverCard.Root openDelay={120}>
              <HoverCard.Content sideOffset={24} align="start">
                <Card css={{ p: 24, width: 240 }}>
                  <Flex css={{ gap: '$4' }} direction="column">
                    <a target="_blank" href={`https://goplatinum.xyz/about`}>
                      <NavItem>About Platinum</NavItem>
                    </a>
                    <a target="_blank" href={`https://discord.gg/zFYhAx99JB`}>
                      <NavItem>Discord</NavItem>
                    </a>

                    <a
                      target="_blank"
                      href={`https://docs.reservoir.tools/reference/overview`}
                    >
                      <NavItem>API Reference</NavItem>
                    </a>

                    <a
                      target="_blank"
                      href={`https://github.com/reservoirprotocol`}
                    >
                      <NavItem>Github</NavItem>
                    </a>

                    <a href={`https://testnets.reservoir.tools`}>
                      <NavItem>Testnet Explorer</NavItem>
                    </a>
                  </Flex>
                </Card>
              </HoverCard.Content>
            </HoverCard.Root>
          </Box>
          {isConnected && (
            <Link href={`/portfolio/${address || ''}?chain=${routePrefix}`}>
              <Box css={{ mr: '$2' }}>
                <NavItem>Portfolio</NavItem>
              </Box>
            </Link>
          )}
        </Flex>
        {userData?.displayName ? <FarcasterSidebar /> : <SignIn />}
        {isConnected ? (
          <AccountSidebar />
        ) : (
          <Box css={{ maxWidth: '185px' }}>
            <ConnectWalletButton />
          </Box>
        )}
        <CartButton />
      </Flex>
    </Flex>
  )
}

export default Navbar
