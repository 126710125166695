import React, { useContext } from 'react'
import { Button } from 'components/primitives'
import { Root as DialogRoot, DialogTrigger } from '@radix-ui/react-dialog'
import {
  faClose,
  faMagnifyingGlass,
  faMusic,
  faPlay,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AudioContext } from 'context/AudioContextProvider'

const MobilePlayerButton = () => {
  const { isPlayerMobileOpen, setIsPlayerMobileOpen } = useContext(AudioContext)
  return (
    <DialogRoot modal={false}>
      <DialogTrigger asChild>
        <Button
          css={{ justifyContent: 'center', width: '44px', height: '44px' }}
          type="button"
          size="small"
          color="gray3"
          onClick={() => setIsPlayerMobileOpen(!isPlayerMobileOpen)}
        >
          <FontAwesomeIcon
            icon={!isPlayerMobileOpen ? faMusic : faClose}
            width={16}
            height={16}
          />
        </Button>
      </DialogTrigger>
    </DialogRoot>
  )
}

export default MobilePlayerButton
